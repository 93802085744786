import React from "react";
import "./modal.css";

const Modal = ({ isOpen, onClose, children }) => {
  if (isOpen)
    return (
      <div
        className="modal"
        onClick={(e) => {
          e.stopPropagation();
          onClose();
        }}
      >
        <div className="modal-body" onClick={(e) => e.stopPropagation()}>
          {children}
          <button onClick={onClose} className="view-btn view-btn--light-theme">
            Close
          </button>
        </div>
      </div>
    );
};

export default Modal;

import React from "react";

export default function TodoItem({ todo, onDone }) {
  return (
    <div key={todo.id} className="col-sm-6">
      <div className={todo.done ? "card-todo done m-3" : "card-todo m-3"}>
        <div className="card-details">
          <p className={todo.done ? "text-title done" : "text-title"}>
            {todo.title}
          </p>
          <p className="text-body">{todo.body}</p>
        </div>
        <button
          className="card-button"
          onClick={() => onDone(todo.id)}
          disabled={todo.done}
        >
          Done
        </button>
      </div>
    </div>
  );
}

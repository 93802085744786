import logo from '../imgs/white-logo.svg'
export default function Footer(){
    return(
        <footer className="footer p-3">
            <div className="row p-3">
                <h2 className="text-center">Follow me on :</h2>
                <div className="social-bar">
                    <a href="https://www.instagram.com/mo.official_6/" ><i className='bx bxl-instagram-alt' ></i></a>
                    <a href="https://www.facebook.com/mohammed.dbs.3" ><i className='bx bxl-facebook-circle'></i></a>
                    <a href="https://twitter.com/mo_binsahl" ><i className='bx bxl-twitter'></i></a>
                    <a href="mailto:mohammed.dbs.660@gmail.com" ><i className='bx bxs-envelope' ></i></a>
                    <a href="t.me/mohammeddbs" ><i className='bx bxl-telegram' ></i></a>
                    <a href="https://github.com/mohammedBinSahl6" ><i className='bx bxl-github' ></i></a>
                    <a href="https://www.linkedin.com/in/mohammed-bin-sahl-a79a5023b/" ><i className='bx bxl-linkedin-square' ></i></a>
                </div>
            </div>
            <hr />

            <div className="row p-3 mt-5">
                <img className="footer-logo" src={logo} alt='logo' />
                <h5 className="text-center">© 2023 Mohammed bin sahl</h5>
            </div>
        </footer>
    )
}
import my1 from "../imgs/my-pic.jpg";
import my2 from "../imgs/myAbout.svg";
import art from "../imgs/gla.svg";
import { useEffect, useRef } from "react";
export default function About({ setNavbar }) {
  const myref = useRef();
  useEffect(() => {
    myref.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  return (
    <div ref={myref} className="container-fluid about">
      <h1 className="text-center">
        About <span>Me</span>
      </h1>
      <div className="row first-sec">
        <div className="col-sm-6 p-3 f-holder left-side">
          <img className="about-img-1 mt-5" src={my1} alt="about2" />
          <img className="about-gla" src={art} alt="arts" />
        </div>
        <div className="col-sm-6   right-side">
          <h2 className="text-center job">Front-End Developer</h2>
          <p className="text-center bio">
            Junior Front-End Developer with a passion for creating innovative,
            user-friendly web applications. Experienced in HTML, CSS,
            JavaScript, and ReactJS. Skilled at delivering modern designs while
            optimizing performance and scalability. Driven to help teams succeed
            by leveraging problem solving strategies and communication skills.
          </p>
          <div className="skills-div p-3">
            <h2 className="text-center skills-head">Skills</h2>
            <h3 className="text-center text-white">Web Developer</h3>
            <div className="f-holder ss pt-5">
             <button className="skill">HTML</button>
             <button className="skill">CSS</button>
             <button className="skill">Javascript</button>
             <button className="skill">Typescript</button>
             <button className="skill">React</button>
             <button className="skill">Angular</button>
             <button className="skill">Redux</button>
             <button className="skill">Python</button>
             <button className="skill">Flask</button>
             <button className="skill">SQL</button>
             <button className="skill">SPFX</button>
             <button className="skill">ExpressJS</button>
            </div>

            <h3 className="text-center text-white mt-5">Mobile Developer</h3>
            <div className="f-holder pt-5">
             <button className="skill">React Native</button>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid more-about-sec pt-5 pb-5">
        <div className="row p-3">
          <div className="col-sm p-3">
            <div className="f-holder pt-5">
              <img
                className="about-img-2"
                src={my2}
                data-aos="fade-up"
                alt="about"
              />
            </div>
          </div>
          <div className="col-sm p-3 ">
            <h3 className="text-center subhead pt-5" data-aos="fade-up">
              Why Programming?
            </h3>
            <p className="text-center why-p" data-aos="fade-up">
              I have discovered myself in the captivating realm of programming,
              where I find immense joy in crafting innovative applications that
              not only satisfy my curiosity but also ignite a passion within me.
              Developing these intricate digital creations has become an
              exhilarating journey, infusing each line of code with a profound
              sense of fulfillment and excitement.
            </p>

            <h3 className="text-center subhead pt-5" data-aos="fade-up">
              More About
            </h3>
            <div className=" pt-5">
              <h4>
                <i class="bx bxs-location-plus"></i> Living in Egypt
              </h4>
              <h4>
                <i class="bx bx-code-alt"></i> 1+ yrs Experience
              </h4>
            </div>
            <h4 className="">
              <i class="bx bxs-briefcase"></i> Working at{" "}
              <a href="https://viu.ch">VIU</a>.
            </h4>

            <h5 className="text-center follow-head pt-5">Follow me On </h5>

            <div className="f-holder">
              <a
                className="twitter"
                href="https://www.linkedin.com/in/mohammed-bin-sahl-a79a5023b/"
              >
                <i class="bx bxl-linkedin"></i>
                <span>LinkedIn</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

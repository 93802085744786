export default function Glasses({ eyesColor }) {
  return (
    <div className="glasses">
      <svg viewBox="0 0 226 91" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Group 3">
          <path
            id="Vector"
            d="M16.0283 90.5552C11.3108 90.5552 7.46002 89.0632 4.47601 86.0792C1.49199 83.0952 0 79.2444 0 74.5269V0H226V73.9448C226 78.658 224.508 82.5088 221.524 85.4971C218.54 88.4854 214.689 89.9774 209.972 89.9731H142.819C136.846 89.9731 131.84 87.278 127.799 81.8878L118.272 65.976L107.276 83.0133C104.192 88.0413 99.3865 90.5552 92.8579 90.5552H16.0283ZM140.218 69.7599H208.238V20.0515H126.648V49.6566L140.218 69.7599ZM17.7618 70.342H95.8851L109.171 49.98V20.0515H17.7618V70.342Z"
            fill="#FFF34F"
          />
          <path
            id="eye1"
            d="M60.238 68.7703C55.5315 68.7703 50.9307 67.3746 47.0174 64.7598C43.104 62.145 40.0539 58.4285 38.2528 54.0802C36.4517 49.732 35.9805 44.9472 36.8987 40.3311C37.8169 35.715 40.0833 31.4749 43.4113 28.1469C46.7393 24.8189 50.9794 22.5524 55.5955 21.6342C60.2116 20.716 64.9963 21.1873 69.3446 22.9884C73.6929 24.7895 77.4094 27.8396 80.0242 31.7529C82.639 35.6663 84.0346 40.2671 84.0346 44.9736C84.0278 51.2828 81.5185 57.3316 77.0572 61.7928C72.596 66.2541 66.5472 68.7634 60.238 68.7703ZM60.238 33.0721C57.8838 33.0708 55.5822 33.7677 53.6241 35.0747C51.6661 36.3817 50.1396 38.24 49.2378 40.4146C48.336 42.5892 48.0994 44.9824 48.5579 47.2915C49.0164 49.6006 50.1494 51.7219 51.8136 53.387C53.4778 55.0521 55.5985 56.1863 57.9073 56.646C60.2162 57.1058 62.6095 56.8704 64.7846 55.9698C66.9597 55.0692 68.8188 53.5438 70.1269 51.5865C71.4349 49.6291 72.1331 47.3278 72.1331 44.9736C72.1314 41.8188 70.8778 38.7936 68.6476 36.5621C66.4174 34.3307 63.3929 33.0755 60.238 33.0721Z"
            fill={eyesColor}
          />
          <path
            id="eye2"
            d="M169.881 68.7703C165.174 68.7703 160.573 67.3746 156.66 64.7598C152.747 62.145 149.697 58.4285 147.895 54.0802C146.094 49.732 145.623 44.9472 146.541 40.3311C147.459 35.715 149.726 31.4749 153.054 28.1469C156.382 24.8189 160.622 22.5524 165.238 21.6342C169.854 20.716 174.639 21.1873 178.987 22.9884C183.335 24.7895 187.052 27.8396 189.667 31.7529C192.282 35.6663 193.677 40.2671 193.677 44.9736C193.67 51.2828 191.161 57.3316 186.7 61.7928C182.239 66.2541 176.19 68.7634 169.881 68.7703ZM169.881 33.0721C167.526 33.0708 165.225 33.7677 163.267 35.0747C161.309 36.3817 159.782 38.24 158.88 40.4146C157.979 42.5892 157.742 44.9824 158.2 47.2915C158.659 49.6006 159.792 51.7219 161.456 53.387C163.12 55.0521 165.241 56.1863 167.55 56.646C169.859 57.1058 172.252 56.8704 174.427 55.9698C176.602 55.0692 178.461 53.5438 179.769 51.5865C181.078 49.6291 181.776 47.3278 181.776 44.9736C181.772 41.8193 180.518 38.7951 178.288 36.564C176.058 34.333 173.035 33.0772 169.881 33.0721Z"
            fill={eyesColor}
          />
        </g>
      </svg>
    </div>
  );
}

import "./App.css";
import "./css/mobile.css";
import "./css/bootstrap.min.css";
import Footer from "./components/Footer";
import Home from "./components/home/Home";
import Navbar from "./components/Navbar";
import Aos from "aos";
import "aos/dist/aos.css";
import { Route, Routes, useLocation } from "react-router-dom";
import Portfolio from "./components/portfolio/Portfolio";
import About from "./components/About";
import { useEffect, useState } from "react";
import Todo from "./components/Todo";
import Menubar from "./components/Menubar";
Aos.init();
function App() {
  const [navbar, setNavbar] = useState(false);
  const [menu, setMenu] = useState(false);
  const location = useLocation();
  const changeNav = () => {
    if (
      window.scrollY >= 80 ||
      location.pathname.toLocaleLowerCase().includes("about") ||
      location.pathname.toLocaleLowerCase().includes("portfolio")
    ) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  window.addEventListener("scroll", changeNav);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className="App">
      <Navbar navbar={navbar} setNavbar={setNavbar} setMenu={setMenu} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/todo" element={<Todo />} />
        <Route path="/about" element={<About setNavbar={setNavbar} />} />
      </Routes>
      <Footer />
      <Menubar menu={menu} setMenu={setMenu} />
      <a className="msg-btn f-holder" href="https://t.me/mohammeddbs">
        <i className="bx bxl-telegram"></i>
      </a>
    </div>
  );
}

export default App;

import React from "react";
import { Link } from "react-router-dom";

export default function Menubar({ menu, setMenu }) {
  return (
    <section className={menu ? "menubar active" : "menubar"}>
      <header className="menubar-header">
        <i className="bx bx-x" onClick={() => setMenu(false)}></i>
      </header>

      <ul className="menubar-links">
        <li className="menubar-link" onClick={() => setMenu(false)}>
          <Link className="link" to="/">
            Home
          </Link>
        </li>
        <li className="menubar-link" onClick={() => setMenu(false)}>
          <Link className="link" to="/#contact">
            Contact
          </Link>
        </li>
        <li className="menubar-link" onClick={() => setMenu(false)}>
          <Link className="link" to="/portfolio">
            Portfolio
          </Link>
        </li>
        <li className="menubar-link" onClick={() => setMenu(false)}>
          <Link className="link" to="/about">
            About
          </Link>
        </li>
      </ul>
    </section>
  );
}

import { works } from "../../data/work";
import Work from "../work/Work";
import "./portfolio.css";
import Glasses from "../Glasses";

export default function Portfolio() {
  return (
    <div className="portfolio-page starter">
      <header className="portfolio-page__land">
        <Glasses eyesColor={"#3c0b9a"} />{" "}
        <div className="portfolio-page__land-inner">
          <h2 className="portfolio-page__land-subtitle">My Work</h2>
          <p className="portfolio-page__land-description">
            Here are some projects I have worked on, in no particular order.
          </p>
        </div>
      </header>
      <hr />
      <div className="home-page__work-items">
        {works.map((work) => (
          <Work
            description={work.describe}
            image={
              !work.image.includes(".")
                ? require(`../../imgs/${work.image}.jpg`)
                : require("../../imgs/" + work.image)
            }
            key={work.id}
            link={work.url}
            title={work.title}
          />
        ))}
      </div>
    </div>
  );
}

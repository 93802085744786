import { Link } from "react-router-dom";
import logo from "../imgs/loogo1.svg";
import whiteLogo from "../imgs/white-logo.svg";
export default function Navbar({ navbar, setMenu }) {
  return (
    <nav
      className={
        navbar
          ? "navbar navbar-expand-sm bg-light navbar-light fixed-top"
          : "navbar navbar-expand-sm  navbar-dark fixed-top"
      }
    >
      <div className="container-fluid">
        <a className="navbar-brand" href="/">
          <img
            className="nav-logo"
            src={navbar ? logo : whiteLogo}
            alt="navbar logo"
          />
        </a>
        <i className="bx bx-menu" onClick={() => setMenu(true)}></i>
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link className="nav-link" to="/portfolio">
                Portfolio
              </Link>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/#contact">
                Contact
              </a>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/about">
                About
              </Link>
            </li>
          </ul>
        </div>
    </nav>
  );
}

import React from "react";
import "./work.css";

export default function Work({ title, description, image, link }) {
  return (
    <div className="work-card" onClick={() => (window.location.href = link)}>
      <img className="work-card__image" src={image} alt="work" />
      <div className="work-card__content">
        <p className="work-card__title">{title}</p>
        <p className="work-card__description">{description}</p>
      </div>
    </div>
  );
}
